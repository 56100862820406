<template>
  <bs-popup id="base-price-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form" :is-large="true">
    <template v-slot:title>Base Price</template>
    <template v-slot:content>
      <bs-error-message :errors="errorMessages"/>
      <div class="mb-3">
        <label for="nama" class="form-label">Profil</label>
        <base-price-profile-combo v-model="model.profilId" :textDefault="model.profilNama"/>
      </div>
      <div class="mb-3" v-if="model.profilId">
        <label for="nama" class="form-label">Bulan</label>
        <month-combo v-model="month" :textDefault="monthNama"/>
      </div>
      <div class="mb-3" v-if="model.profilId">
        <button class="btn btn-sm btn-primary" @click="generateBasePrice">Generate Base Price</button>
      </div>
      <div class="seperator" v-if="showGenerated && this.generated.length>0">
        <h5></h5>
      </div>
      <div class="mt-3" v-if="showGenerated && this.generated.length>0">
        <div class="mb-3" v-for="(item,index) in this.generated" :key="`formValidity-${index}`">
          <div class="mb-3 row">
            <div class="col">
              <label for="code" class="form-label">Remark</label>
              <bs-textbox v-model="item.remarks"/>
            </div>
            <div class="col">
              <label for="code" class="form-label">Quantity</label>
              <bs-textbox type="number" v-model="item.quantity"/>
            </div>
            <div class="col-2">
              <label for="code" class="form-label">Currency/Unit</label>
              <bs-plain-text :model-value="`${profile.currencyCode}/${profile.unitCode}`"/>
            </div>
            <div class="col">
              <label for="code" class="form-label">Valid From</label>
              <bs-date-picker v-model="item.validityStart" :disabled="profile.timeRangeId === 3"/>
            </div>
            <div class="col">
              <label for="code" class="form-label">Valid To</label>
              <bs-date-picker v-model="item.validityEnd" :disabled="profile.timeRangeId === 3"/>
            </div>
          </div>
        </div>
      </div>

    </template>
    <template v-slot:footer>
      <button class="btn btn-sm btn-primary" @click="saveData"><i class="bi bi-check-circle"></i> Save</button>
      <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
    </template>
  </bs-popup>
</template>

<script>
import BasePriceProfileService from "@/services/base-price-profile-service";
import BasePriceService from "@/services/base-price-service";
import BasePriceProfileCombo from "@/components/BasePriceProfileCombo.vue";
import MonthCombo from "@/components/MonthCombo.vue";
import {getCurrentYear, getFirstGivenIndexDate, getMonthDateRange} from "@/plugins/helper";
import BsDatePicker from "@/plugins/components/BsDatePicker.vue";
import Moment from "moment";

export default {
  components: {
    BsDatePicker,
    MonthCombo,
    BasePriceProfileCombo
  },
  data: function () {
    return {
      id: 0,
      model: BasePriceService.default(),
      errorMessages: null,
      profile: null,
      month: 0,
      monthNama: '',
      showGenerated: false,
      generated: [],
      validFrom: null,
      remarks: '',
      quantity: 0
    }
  },
  methods: {
    async generateBasePrice() {
      this.showGenerated = false;

      // call profile and set
      if (this.model.profilId && this.month) {
        let response = await BasePriceProfileService.getObject(this.model.profilId);
        this.profile = response.data;
        if (response.status) {
          this.errorMessages = null;
        } else {
          this.errorMessages = response.errorMessages;
        }

        //generate form -> push default ke generated sesuai profile yg dipilih
        this.generated = [];

        if (this.profile) {
          const dateRange = getMonthDateRange(getCurrentYear(), this.month);
          if (this.profile.timeRangeId === 1) {

            let pointerStart = null;
            let pointerEnd = null;

            const firstDay = getFirstGivenIndexDate(getCurrentYear(), this.month, this.profile.startDay || 1);
            console.log(firstDay.toDate());
            console.log(dateRange.start.toDate());
            console.log(dateRange.end.toDate());
            if (firstDay.date()===dateRange.start.date()){
              pointerStart = Moment([dateRange.start.year(), dateRange.start.month(), dateRange.start.date()]);
              pointerEnd = Moment([dateRange.start.year(), dateRange.start.month(), dateRange.start.date() + 6]);
            }else{
              pointerStart = Moment([dateRange.start.year(), dateRange.start.month(), dateRange.start.date()]);
              pointerEnd = Moment([firstDay.year(), firstDay.month(), firstDay.date()-1]);
            }
            while (pointerStart.isSame(dateRange.end,'month')){
              const temp = BasePriceService.modelFormElement();
              temp.validityStart = pointerStart.toDate();
              if (pointerEnd.isSame(dateRange.end,'month')){
                temp.validityEnd = pointerEnd.toDate();
              }else{
                temp.validityEnd = dateRange.end.toDate();
              }
              this.generated.push(temp);
              if (pointerEnd.diff(pointerStart,'days')===6){
                pointerStart.add(7,'day');
                console.log('pointerStart', pointerStart.toDate())
                pointerEnd.add(7,'day');
                console.log('pointerEnd', pointerEnd.toDate())
              }else{
                pointerStart.set({'year': pointerEnd.year(), 'month': pointerEnd.month(),'date':pointerEnd.date()+1})
                console.log('pointerStart', pointerStart.toDate())
                pointerEnd.set({'year': pointerStart.year(), 'month': pointerStart.month(),'date':pointerStart.date()+6})
                console.log('pointerEnd', pointerEnd.toDate())
              }
            }
          }
          if (this.profile.timeRangeId === 2) {
            const temp1 = BasePriceService.modelFormElement();
            const temp2 = BasePriceService.modelFormElement();
            if (dateRange.end.day() === 28 || dateRange.end.day() === 29) {
              temp1.validityStart = dateRange.start.toDate();
              temp1.validityEnd = dateRange.start.add(13, 'day').toDate();
              temp2.validityStart = dateRange.start.add(1, 'day').toDate();
              temp2.validityEnd = dateRange.end.toDate()
              this.generated.push(temp1);
              this.generated.push(temp2);
            } else {
              temp1.validityStart = dateRange.start.toDate();
              temp1.validityEnd = dateRange.start.add(14, 'day').toDate();
              temp2.validityStart = dateRange.start.add(1, 'day').toDate();
              temp2.validityEnd = dateRange.end.toDate()
              this.generated.push(temp1);
              this.generated.push(temp2);
            }
          }
          if (this.profile.timeRangeId === 3) {
            const temp = BasePriceService.modelFormElement();
            temp.validityStart = dateRange.start.toDate();
            temp.validityEnd = dateRange.end.toDate()
            this.generated.push(temp);
          }
        } else {
          alert(`Base Price Tidak tergenerate. Terjadi Kesalahan pada sistem`)
        }
      } else {
        alert('Silahkan pilih profil & bulan terlebih dahulu.')
      }
      this.showGenerated = true;
    },
    async loadData() {
      if (this.id === undefined || this.id === null || this.id === 0) {
        return;
      }

      const result = await BasePriceProfileService.getObject(this.id);
      if (result.status) {
        this.errorMessages = result.errorMessages;
      } else {
        this.errorMessages = null;
      }

      this.model = result.data;
    },
    async onPopupClosed() {
      this.$router.push('/base-price');
    },
    async close() {
      this.$refs.form.closePopup();
    },
    async saveData() {
      try {
        this.$store.commit('progressCounterQueueIncrement');
        this.model.generated = [];
        this.generated.forEach((item) => {
          item.profilId = this.model.profilId;
          this.model.generated.push(BasePriceService._setDefaultValueForEdit(item))
        });
        let status = false;
        const result = await BasePriceService.generate(this.model.generated);
        status = result.status;
        if (status) {
          this.errorMessages = null;
        } else {
          this.errorMessages = result.errorMessages;
        }

        if (!status) return;

        this.$refs.form.closePopup();

        this.$router.push(`/base-price`);

      } finally {
        this.$store.commit('progressCounterQueueDecrement');
      }
    }
  }
}
</script>

<style scoped>
.seperator h5 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
}

.seperator h5::before,
.seperator h5::after {
  content: "";
  display: block;
  flex-grow: 1;
  height: 1px;
  background: #ccc;
}

.seperator h5 span {
  padding: 0 2em;
}
</style>